<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-grid"/> Domains
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDataTable
                :itemsPerPage="15"
                border
                sorter
                :loading="isLoading"
                :items="normalizedDomainItems"
                :fields="columns">

              <template #loading>
                <CElementCover
                    :boundaries="[
                      { sides: ['top'], query: 'td' },
                      { sides: ['bottom'], query: 'tbody' }
                    ]"
                >
                  <h1 class="d-inline">Loading... </h1><CSpinner size="5xl" color="info"/>
                </CElementCover>
              </template>

              <template #requests_used-header>
                Usage
                <template v-if="isAgency">
                  <span v-if="quota" class="requests-quota"> / {{formatNumber(quota)}} <template v-if="agencyRequestsDropped">/ </template></span>
                  <span v-if="agencyRequestsDropped" class="requests-dropped">{{formatNumber(agencyRequestsDropped)}}</span>
                </template>
                <span class="question-mark-icon" v-c-tooltip="{
                    content: 'Number of requests made since the beginning of the month / Quota / Dropped (if any)',
                    placement: 'bottom'
                  }">?</span>
              </template>

              <template #domain="{item}">
                <td>
                  <a target="_blank" :href="`//${item.domain}`">{{item.domain}}</a>
                </td>
              </template>

              <template #requests_used="{item}">
                <td>
                  <template v-if="isAgency">
                    <span class="requests-used">{{formatNumber(item.requests_used)}}</span>
                  </template>
                  <template v-else>
                    <span class="requests-used">{{formatNumber(item.requests_used)}}</span>
                    <span class="requests-quota"> / {{formatNumber(quota)}} <template v-if="item.requests_dropped">/ </template></span>
                    <span v-if="item.requests_dropped" class="requests-dropped">{{formatNumber(item.requests_dropped)}}</span>
                  </template>
                </td>
              </template>

              <template #key="{item}">
                <td>
                  <a :href="item.logs_url">View</a><span>&nbsp;|&nbsp;</span>
                  <router-link :to="{ path: 'log-export', query: { domain: item.domain } }">Download</router-link>
                </td>
              </template>

              <template #sub_status="{item}">
                <td>
                  <div class="sub-status-row">
                    <CSwitch
                        v-if="!domainNeedsReload(item.domain)"
                        color="success"
                        shape="pill"
                        :checked="!item.add_url || item.enabled"
                        :disabled="!item.add_url"
                        @update:checked="handleStatusSwitch(item)"
                    />
                    <CButton v-if="domainNeedsReload(item.domain)"
                             color="light"
                             shape="pill"
                             title="Reload the page"
                             @click.prevent="loadDomains()"><CIcon style="margin-top: -2px;" name="cil-sync"/></CButton>
                    <a href="#"
                       v-if="item.removable"
                       class="remove-btn" @click.prevent="removeDomain(item)">Remove</a>
                  </div>
                </td>
              </template>
            </CDataTable>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import dayjs from "dayjs";
import DomainService from "@/services/DomainService";

export default {
  name: 'Logs',
  components: {},
  data() {
    return {
      isLoading: false,
      columns: [
        {key: 'domain', label: 'Domain'},
        {key: 'requests_used', label: 'Usage', _style: 'overflow: initial'},
        {key: 'key', label: 'Logs', sorter: false},
        {key: 'created_at', label: 'Registered At', sorter: false},
        {key: 'sub_status', label: 'Status', sorter: false},
      ],
      domainsItems: [],
      domainsNeedReload: [],
      isAgency: false,
      quota: 0,
      agencyRequestsDropped: 0
    }
  },
  methods: {
    async loadDomains() {
      try {
        this.isLoading = true;
        const response = await DomainService.getAll();

        const {domains, is_agency, quota, dropped = null} = response.data;

        this.domainsNeedReload = [];
        this.domainsItems = domains;
        this.isAgency = is_agency;
        this.quota = quota;
        this.agencyRequestsDropped = dropped;

        this.isLoading = false;

      } finally {
        this.isLoading = false;
      }
    },
    getColorByStatus(status) {
      switch(status) {
        case 'active': return 'success';
        case 'suspended': return 'secondary';
        case 'pending_deposit': return 'warning';
        case 'cancelled': return 'danger';
        default: return 'primary';
      }
    },
    formatNumber(number) {
      return number.toLocaleString();
    },
    async removeDomain(item) {

      if(!confirm('Are you sure?')) return;

      try {
        this.isLoading = true;

        await DomainService.delete(item.domain);

      } finally {
        this.isLoading = false;
      }

      await this.loadDomains();
    },
    handleStatusSwitch(item) {

      !this.domainsNeedReload.includes(item.domain) && this.domainsNeedReload.push(item.domain);

      item.add_url && window.open(item.add_url, '_blank');
    },
    domainNeedsReload(domain) {
      return this.domainsNeedReload.includes(domain) || false;
    }
  },
  computed: {
    normalizedDomainItems() {
      return this.domainsItems.map(el => {

        return {
          domain: el.domain,
          requests_used: el.requests.used,
          requests_dropped: el.requests.dropped,
          key: el.network_id + el.domain + el.domain_key,
          created_at: dayjs.unix(el.created_at).format('MMM DD YYYY'),
          sub_status: el.sub_status || '-',
          sub_cancel_at: el.sub_cancel_at || '',
          logs_url: el.logs_url || '',
          add_url: el.add_url || '',
          enabled: el.enabled || false,
          removable: el.removable || false,
        }
      });
    }
  },
  mounted() {
    this.loadDomains()
  }
}
</script>

<style lang="scss">
.question-mark-icon {
  color: #888;
  display: inline-block;
  background-color: #ececec;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  text-align: center;
  font-size: 12px;
  cursor: default;
  float: right;
  line-height: 18px;
}
.requests-used {
  color: #222;
  font-weight: normal;
}
.requests-quota {
  color: #aeaeae;
  font-weight: normal;
}
.requests-dropped {
  color: red;
  font-weight: normal;
}
.sub-status-row {
  display: flex;
  align-items: center;

  .remove-btn {
    vertical-align: top;
    color: #e55353 !important;
    margin-left: auto;
  }
}
</style>