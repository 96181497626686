var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('CCard',[_c('CCardHeader',[_vm._t("header",function(){return [_c('CIcon',{attrs:{"name":"cil-grid"}}),_vm._v(" Domains ")]})],2),_c('CCardBody',[_c('CDataTable',{attrs:{"itemsPerPage":15,"border":"","sorter":"","loading":_vm.isLoading,"items":_vm.normalizedDomainItems,"fields":_vm.columns},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('CElementCover',{attrs:{"boundaries":[
                    { sides: ['top'], query: 'td' },
                    { sides: ['bottom'], query: 'tbody' }
                  ]}},[_c('h1',{staticClass:"d-inline"},[_vm._v("Loading... ")]),_c('CSpinner',{attrs:{"size":"5xl","color":"info"}})],1)]},proxy:true},{key:"requests_used-header",fn:function(){return [_vm._v(" Usage "),(_vm.isAgency)?[(_vm.quota)?_c('span',{staticClass:"requests-quota"},[_vm._v(" / "+_vm._s(_vm.formatNumber(_vm.quota))+" "),(_vm.agencyRequestsDropped)?[_vm._v("/ ")]:_vm._e()],2):_vm._e(),(_vm.agencyRequestsDropped)?_c('span',{staticClass:"requests-dropped"},[_vm._v(_vm._s(_vm.formatNumber(_vm.agencyRequestsDropped)))]):_vm._e()]:_vm._e(),_c('span',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: 'Number of requests made since the beginning of the month / Quota / Dropped (if any)',
                  placement: 'bottom'
                }),expression:"{\n                  content: 'Number of requests made since the beginning of the month / Quota / Dropped (if any)',\n                  placement: 'bottom'\n                }"}],staticClass:"question-mark-icon"},[_vm._v("?")])]},proxy:true},{key:"domain",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('a',{attrs:{"target":"_blank","href":("//" + (item.domain))}},[_vm._v(_vm._s(item.domain))])])]}},{key:"requests_used",fn:function(ref){
                var item = ref.item;
return [_c('td',[(_vm.isAgency)?[_c('span',{staticClass:"requests-used"},[_vm._v(_vm._s(_vm.formatNumber(item.requests_used)))])]:[_c('span',{staticClass:"requests-used"},[_vm._v(_vm._s(_vm.formatNumber(item.requests_used)))]),_c('span',{staticClass:"requests-quota"},[_vm._v(" / "+_vm._s(_vm.formatNumber(_vm.quota))+" "),(item.requests_dropped)?[_vm._v("/ ")]:_vm._e()],2),(item.requests_dropped)?_c('span',{staticClass:"requests-dropped"},[_vm._v(_vm._s(_vm.formatNumber(item.requests_dropped)))]):_vm._e()]],2)]}},{key:"key",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('a',{attrs:{"href":item.logs_url}},[_vm._v("View")]),_c('span',[_vm._v(" | ")]),_c('router-link',{attrs:{"to":{ path: 'log-export', query: { domain: item.domain } }}},[_vm._v("Download")])],1)]}},{key:"sub_status",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('div',{staticClass:"sub-status-row"},[(!_vm.domainNeedsReload(item.domain))?_c('CSwitch',{attrs:{"color":"success","shape":"pill","checked":!item.add_url || item.enabled,"disabled":!item.add_url},on:{"update:checked":function($event){return _vm.handleStatusSwitch(item)}}}):_vm._e(),(_vm.domainNeedsReload(item.domain))?_c('CButton',{attrs:{"color":"light","shape":"pill","title":"Reload the page"},on:{"click":function($event){$event.preventDefault();return _vm.loadDomains()}}},[_c('CIcon',{staticStyle:{"margin-top":"-2px"},attrs:{"name":"cil-sync"}})],1):_vm._e(),(item.removable)?_c('a',{staticClass:"remove-btn",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.removeDomain(item)}}},[_vm._v("Remove")]):_vm._e()],1)])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }